const initialState = {
  baseUrl: "",
};

const baseUrl = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BASE_URL":
      return {
        ...state,
        baseUrl: action.payload,
      };
    default:
      return state;
  }
};

export default baseUrl;
