import { history } from "../../../history";
import { toast } from "react-toastify";
export const logoutAdmin = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_ADMIN", payload: {} });
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_data");
    toast.success("Logout Successfully !", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      pauseOnHover: false,
      closeOnClick: true,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
    });
    // alert("Logout Successfully !");
    history.push("/page/login");
  };
};
